<!--
 * @Author: wjj
 * @Date: 2021-08-11 14:25:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 11:09:27
 * @Description: 客户配置
-->

<template>
  <NeoPageLayout>
    <div class="customer-config">
      <!-- 标题 -->
      <NeoBigTitle class="title" :text="agencyInfo.cusName | defined" />
      <!-- 分页 -->
      <div class="radio">
        <a-radio-group v-model="tab">
          <a-radio-button value="baseInfo">{{ $t('customerConfig.base_info') }}</a-radio-button>
          <a-radio-button :disabled="!agencyInfo.supStatus" value="manager" v-if="$g.isSupSpm">{{
            $t('customerConfig.manager')
          }}</a-radio-button>
          <a-radio-button :disabled="!agencyInfo.supStatus" value="productionConfig">{{
            $t('customerConfig.product_line_config')
          }}</a-radio-button>
        </a-radio-group>
      </div>
      <!-- 基本信息 -->
      <div v-show="tab1">
        <a-spin :spinning="loading1">
          <!-- 基本信息模块 -->
          <div class="baseInfo">
            <div class="title-btn-container">
              <NeoTitle :text="$t('companyInfo.base_info')" />
              <!-- 确认按钮 -->
              <template v-if="permission && canShowbtn">
                <a-button
                  type="primary btn-update"
                  size="small"
                  :loading="confirmBtnLoading"
                  @click="updateSupStatus"
                  v-if="!agencyInfo.supStatus"
                  >{{ $t('customerConfig.confirm') }}</a-button
                >
                <NeoTag v-else type="success" :shape="false" className="confirmTag">{{
                  $t('customerConfig.status_1')
                }}</NeoTag>
              </template>
            </div>
            <!-- 基本信息描述列表 -->
            <a-descriptions bordered size="small" :column="2">
              <a-descriptions-item :label="$t('customerConfig.co_tax')">
                {{ agencyInfo.cusTaxIdNumber }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('customerConfig.supplier_tax')">
                <template>
                  {{ agencyInfo.supTaxIdNumber }}
                </template>
              </a-descriptions-item>
              <a-descriptions-item :label="$t('customerConfig.company_name')">
                {{ agencyInfo.cusName }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('customerConfig.supplier_name')">
                {{ agencyInfo.supName }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('customerConfig.company_address')">
                {{ agencyInfo.cusAddress }}
              </a-descriptions-item>
              <a-descriptions-item :label="$t('customerConfig.supplier_address')">
                {{ agencyInfo.supAddress }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <!-- 报价配置模块  -->
          <div class="quotation-config">
            <div class="title-btn-container">
              <NeoTitle :text="$t('PlineDetails.title_offer_config')" />
            </div>

            <DiscountPresetTable :datap="supDiscountInfo" />
          </div>
          <!-- 价格列表模块  -->
          <div class="priceList">
            <div class="title-btn-container">
              <NeoTitle :text="$t('companyInfo.price_list')" />
            </div>

            <PriceListTable :isRefresh="tab1" :info="{ id: agencyInfo.supEntityId }" />
          </div>
        </a-spin>
      </div>
      <!-- 交付经理 -->
      <div class="manager" v-show="tab2" v-if="$g.isSupSpm">
        <div class="btn-container">
          <a-button type="primary" class="mr-2" @click="addManagerVisible = true" v-if="permission">{{
            $t('customerConfig.btn_new_manager')
          }}</a-button>
          <BatchImport v-if="permission" :agencyInfo="agencyInfo" @refresh="batchAddSuccess" />
          <a-button type="link" :href="$g.deliver_manager_info_template">
            {{ $t('companyInfo.btn_download') }} <a-icon type="download" />
          </a-button>
        </div>
        <!-- 交付经理列表 table -->
        <a-table
          class="head-bg no-br"
          size="middle"
          rowKey="id"
          bordered
          :rowClassName="getRowClass"
          :pagination="managerPagination"
          :data-source="managerInfo"
          :loading="managerLoading"
        >
          <!-- 姓名 -->
          <a-table-column :title="$t('managerInfo.name')" key="name" align="center" :width="150">
            <template slot-scope="text">
              {{ text.name | defined }}
            </template>
          </a-table-column>
          <!-- 邮箱 -->
          <a-table-column :title="$t('managerInfo.email')" key="email" :width="200">
            <template slot-scope="text">
              {{ text.email | defined }}
            </template>
          </a-table-column>
          <!-- 手机 -->
          <a-table-column :title="$t('managerInfo.phone_number')" key="phone" :width="200">
            <template slot-scope="text">
              {{ text.phone === '' ? '---' : text.phone | defined }}
            </template>
          </a-table-column>
          <!-- 部门 -->
          <a-table-column :title="$t('managerInfo.department')" key="department">
            <template slot-scope="text">
              {{ text.department === '' ? '---' : text.department | defined }}
            </template>
          </a-table-column>
          <!-- 权限 -->
          <a-table-column :title="$t('managerInfo.senior_manager')" key="type">
            <template slot-scope="text">
              {{ text.type ? $t('orderInfo.manager') : defined }}
            </template>
          </a-table-column>
          <!-- 状态 -->
          <a-table-column :title="$t('managerInfo.statu')" key="status" align="center">
            <template slot-scope="text">
              {{ text.status ? $t('managerInfo.job') : $t('managerInfo.departure') | defined }}
            </template>
          </a-table-column>
          <!-- 加入时间 -->
          <a-table-column :title="$t('managerInfo.join_time')" key="createDate">
            <template slot-scope="text">
              {{ text.createDate | dateFormat }}
            </template>
          </a-table-column>
          <!-- 操作 -->
          <a-table-column :title="$t('companyInfo.action')" key="action" v-if="permission">
            <template slot-scope="record">
              <!-- 编辑下单经理 -->
              <a :class="record.status === 0 ? '_hide2' : ''" class="mr-3" @click="onEditManager(record)">{{
                $t('companyInfo.action_edit')
              }}</a>
              <!-- 注销下单经理 -->
              <a @click="onLogout(record)">{{
                record.status ? $t('managerInfo.set_departure') : $t('managerInfo.set_jon')
              }}</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <!-- 产品线生产配置 -->
      <div class="baseInfo _pt-20" v-show="tab3">
        <!-- 产线配置table -->
        <a-table
          class="head-bg no-br"
          size="middle"
          bordered
          rowKey="id"
          :data-source="dataInfo"
          :pagination="plinePagination"
          :loading="PlineTableLoading"
        >
          <!-- 产线名 -->
          <a-table-column key="name" :title="$t('companyInfo.Pline_name')">
            <template slot-scope="text">
              {{ text.name | defined }}
            </template>
          </a-table-column>
          <!-- 交付经理 -->
          <a-table-column key="projectManagerName" :title="$t('companyInfo.Pline_manager')">
            <template slot-scope="text">
              {{ text.projectManagerName | defined }}
            </template>
          </a-table-column>
          <!-- 生产对接 -->
          <a-table-column key="produceSystem" :title="$t('companyInfo.Pline_dock')">
            <template slot-scope="text"> {{ localeDict.systemDict[text.produceSystem] | defined }} </template>
          </a-table-column>
          <!-- 行业领域 -->
          <a-table-column key="industry" :title="$t('customerConfig.industry_and_field')">
            <template slot-scope="text"> {{ text.industry | defined }} </template>
          </a-table-column>
          <!-- 创建时间 -->
          <a-table-column key="createDate" :title="$t('customerConfig.create_time')">
            <template slot-scope="text">
              {{ text.createDate || 'undefined' }}
            </template>
          </a-table-column>
          <!-- 操作 -->
          <a-table-column key="action" :title="$t('companyInfo.action')">
            <template slot-scope="text">
              <a class="mr-3" @click="checkDetails(text)">{{ $t('companyInfo.action_details') }}</a>
              <a class="mr-3" @click="showAssignPM(text)" v-if="permission">{{
                text.projectManager != null ? $t('customerConfig.updatePM') : $t('customerConfig.assignPM')
              }}</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
    <!-- 新增产线侧抽屉 -->
    <a-drawer
      width="960px"
      placement="right"
      :title="$t('newPline.add_Pline')"
      :visible="addProdutionLineVisible"
      @close="addProdutionLineVisible = false"
      :destroyOnClose="true"
    >
      <AddProductionLine :id="agencyInfo.id" v-on:show="onAddProductionLine" :clientName="agencyInfo.name" />
    </a-drawer>
    <!-- 新增价格面板 -->
    <a-modal
      :title="priceEdit ? $t('companyInfo.title_edit_price') : $t('companyInfo.title_new_price')"
      :visible="addPriceVisible"
      :footer="null"
      @cancel=";(addPriceVisible = false), (priceEdit = false)"
      :width="684"
      :maskClosable="false"
    >
      <AddPrice
        :id="agencyInfo.id"
        :currency="agencyInfo.currency"
        @addPrice="onAddPrice"
        v-if="addPriceVisible"
        :priceInfo="selectedPrice"
        :edit="priceEdit"
      />
    </a-modal>
    <!-- 新增下单经理侧抽屉 -->
    <a-drawer
      width="460px"
      :title="managerEdit ? $t('customerConfig.edit_manager_title') : $t('customerConfig.new_manager_title')"
      placement="right"
      :visible="addManagerVisible"
      @close=";(addManagerVisible = false), (managerEdit = false)"
    >
      <AddManager
        v-on:show="editManagerInfo"
        :id="agencyInfo.id"
        v-if="addManagerVisible"
        :managerInfo="selectedManager"
        :edit="managerEdit"
      />
    </a-drawer>
    <!-- 分配交付经理弹窗 -->
    <a-modal
      width="700px"
      v-model="assignPMVisible"
      :title="assignPMFlag ? $t('customerConfig.assignPM') : $t('customerConfig.updatePM')"
      :footer="null"
      centered
      @cancel="handleAssignPMCancel"
    >
      <a-form-model :colon="false" ref="assignPmForm" :rules="assignPMFormDataRules" :model="assignPMFormData">
        <div class="addStepOne">
          <a-row>
            <a-col :span="24">
              <a-form-model-item :label="$t('employee.pm')" prop="projectManager">
                <a-select v-model="assignPMFormData.projectManager">
                  <a-select-option v-for="(item, index) in pmList" :key="index" :value="item.userId">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </a-form-model>
      <template>
        <a-button key="submit" type="primary" @click="handleAssignPMOk()">
          {{ $t('submit') }}
        </a-button>
      </template>
    </a-modal>
  </NeoPageLayout>
</template>

<script>
import { mapState } from 'vuex'
import AddPrice from './components/AddPrice'
import AddManager from './components/AddManager'
import AddProductionLine from './components/AddProductionLine'
import NeoTag from '@/components/common/NeoTag'
import DiscountPresetTable from '@/components/DiscountPresetTable'
import PriceListTable from '@/components/PriceListTable'
import BatchImport from './components/BatchImport'

const { localeDict, validate } = window.$g

// import moment from 'moment'
export default {
  name: 'customerConfig',
  components: { AddPrice, AddManager, AddProductionLine, NeoTag, DiscountPresetTable, PriceListTable, BatchImport },
  data() {
    return {
      permission: undefined,
      localeDict,
      companyName: '',
      tab: 'baseInfo',
      tabs: ['baseInfo', 'manager', 'productionConfig'],
      agencyInfo: {},
      text: undefined,
      // input框显示判断
      btnShow: 'waitConfirm',
      // 产线名
      productionLineName: '',
      // 产线配置table-info
      dataInfo: [],
      // 产线配置table假数据
      // dataInfo: Array.from({ length: 31 }).map((item, idx) => ({
      //   name: 'wjj' + idx,
      //   supplierPmName: '韩娜',
      //   produceVersion: '对接Neotrans1.0',
      //   field: '信息技术&杀毒软件',
      //   quoteType: '人工报价',
      //   createDate: '2020年1月24日 12:00',
      // })),
      PlineTableLoading: false,
      plinePagination: {
        current: 1,
        size: 'middle',
        pageSize: 20,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        total: 0,
        onShowSizeChange: (current, pageSize) => {
          this.plinePagination.current = 1
          this.plinePagination.pageSize = pageSize
          this.getProdutionLineInfo()
        },
        onChange: (page) => {
          this.plinePagination.current = page
          this.getProdutionLineInfo()
        },
      },
      PlineId: 0,
      priceEdit: false,
      // 下单经理table-info
      managerInfo: [],
      managerLoading: false,
      managerPagination: {
        current: 1,
        total: 0,
        size: 'middle',
        pageSize: 20,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => {
          this.managerPagination.current = 1
          this.managerPagination.pageSize = pageSize
          this.getManagerInfo()
        },
        onChange: (page) => {
          this.managerPagination.current = page
          this.getManagerInfo()
        },
      },
      managerEdit: false,
      selectedPrice: {},
      selectedManager: {},
      // 价格列表table-info
      addPriceVisible: false,
      addManagerVisible: false,
      addProdutionLineVisible: false,
      checkDetailsVisible: false,
      produceVersion: undefined,
      SpmList: [],
      btnSaveLoading: false,
      selectdata: {
        serviceType: undefined,
        field: undefined,
        documentType: undefined,
        sourceCode: undefined,
        targetCode: undefined,
      },
      // options
      fielName: {
        value: 'dictValue',
        label: 'dictLabel',
        children: 'children',
      },
      selected: false,
      // --------------------------------------------
      tableColumn: [],
      supDiscountInfo: [],
      confirmBtnLoading: false,
      priceList: [],
      loading1: false,
      // 交付经理列表
      pmList: [],
      // 分配交付经理
      assignPMVisible: false,
      assignPMFormData: {
        id: '',
        productLineId: '',
        projectManager: '',
      },
      assignPMFormDataRules: {
        projectManager: [validate.required],
      },
      canShowbtn: false,
      assignPMFlag: false,
    }
  },
  created() {
    this.permission = this.$g.isSupSpm
    this.fetchAll()
  },
  mounted() {},
  watch: {
    tab(v) {
      const tabs = this.tabs
      v === tabs[0] && this.getBaseInfo()
      v === tabs[1] && this.getDeliveryManager()
      v === tabs[2] && this.getProductLineConfig()
    },
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
      langList: (state) => state.langList,
      industryList: (state) => state.industryList,
    }),
    serviceType() {
      return this.$store.getters['app/getDictByType']('SERVICE_TYPE')
    },
    docType() {
      return this.$store.getters['app/getDictByType']('DOC_TYPE')
    },
    tab1() {
      return this.tab === this.tabs[0]
    },
    tab2() {
      return this.tab === this.tabs[1]
    },
    tab3() {
      return this.tab === this.tabs[2]
    },
  },
  methods: {
    getRowClass(record) {
      if (record.status === 0) {
        return 'c-3'
      }
    },
    fetchAll() {
      this.getBaseInfo()
      this.getDeliveryManager()
      this.getProductLineConfig()
      this.getPmList()
    },
    // 基本信息模块
    getBaseInfo() {
      this.getAgencyInfo()
      this.getSupDiscountInfo()
      this.reloadReportRangeColumn()
    },
    // 交付经理模块
    getDeliveryManager() {
      this.getManagerInfo()
    },
    // 产品线生产配置模块
    getProductLineConfig() {
      this.getProdutionLineInfo()
    },
    // 交付经理批量添加成功
    batchAddSuccess() {
      this.getManagerInfo()
    },
    async getManagerInfo() {
      this.managerLoading = true
      const params = {
        limit: this.managerPagination.pageSize,
        page: this.managerPagination.current,
      }
      try {
        const data = await this.$http('supEmployeeDetail', params)
        this.managerInfo = data.list
        this.managerPagination.total = data.total
        this.managerLoading = false
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async updateSupStatus() {
      this.confirmBtnLoading = true
      const simpleSupDisCountRequestList = this.supDiscountInfo.map(({ id, updateDate }) => {
        return { id, updateDate }
      })
      const params = {
        id: this.agencyInfo.supEntityId,
        simpleSupDisCountRequestList,
        updateDate: this.agencyInfo.updateDate,
      }
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('updateSupStatus', params)
        this.confirmBtnLoading = false
        this.$message.success(this.$t('customerConfig.info_has_confirm'))
        this.getAgencyInfo()
      } catch (err) {
        this.confirmBtnLoading = false
        this.$httpNotify(err)
      }
    },
    async getSupDiscountInfo() {
      try {
        const data = await this.$http('supDiscountInfo')
        this.supDiscountInfo = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    async getAgencyInfo() {
      try {
        this.loading1 = true
        const data = await this.$http('getCusAndSupEntityList')
        this.agencyInfo = data
        this.canShowbtn = true
        this.agencyInfo.currency = this.getCurrency(this.agencyInfo.currency)
        this.loading1 = false
      } catch (err) {
        this.loading1 = false
        this.$httpNotify(err)
      }
    },
    // 根据不同的产线版本加载不同的匹配区间
    reloadReportRangeColumn() {
      this.tableColumn = window.$g.wordReportRangeColumn.filter((item) => {
        if (item.produceVersion === 2) {
          return item
        }
      })[0].range
    },
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 下单经理-注销操作响应事件
    async onLogout(value) {
      const status = value.status ? 0 : 1
      try {
        await this.$http('updateDeliverManager', {
          id: value.id,
          status,
        })
        this.$message.success(this.$t('prompt.set_success'))
        this.getManagerInfo()
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
    // 查看详情
    checkDetails(val) {
      // this.productionLineName = val.name
      // this.PlineId = val.id
      // this.checkDetailsVisible = true
      // this.produceVersion = val.produceVersion

      const { id, produceSystem, productLineConfigId } = val

      const query = {
        id,
        produceSystem,
        permission: this.permission,
        clientName: this.agencyInfo.cusName,
        pcId: productLineConfigId,
        comMapping: this.agencyInfo.comMapping,
      }

      console.error(`query`, query)
      this.$router.push({ path: this.$g.route.customerConfig_productLineDetail, query })
    },
    // 获取产线信息集合
    async getProdutionLineInfo() {
      try {
        this.PlineTableLoading = true
        const data = await this.$http('fetchProductionLineList')
        this.PlineTableLoading = false
        this.dataInfo = data
      } catch (err) {
        this.PlineTableLoading = false
        this.$httpNotify(err)
      }
    },

    // 下单经理子组件触发父组件
    editManagerInfo() {
      this.getManagerInfo()
      this.addManagerVisible = false
      this.managerEdit = false
    },
    // 编辑价格
    onEditPrice(val) {
      this.selectedPrice = val
      this.priceEdit = true
      this.addPriceVisible = true
    },
    // 价格子组件触发的父组件的
    onAddPrice() {
      this.getPriceList()
      this.addPriceVisible = false
      this.priceEdit = false
    },
    // 编辑下单经理
    onEditManager(record) {
      this.selectedManager = record
      this.addManagerVisible = true
      this.managerEdit = true
    },
    onAddProductionLine() {
      this.getProdutionLineInfo()
      this.addProdutionLineVisible = false
    },
    // 激活产线
    async onActiveProductionLine(val) {
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('activeProductionLine', {
          id: val,
        })
        this.getProdutionLineInfo()
        this.$message.success(this.$t('companyInfo.active_success'))
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 产线详情被修改
    updateProductionLine() {
      this.checkDetailsVisible = false
      this.getProdutionLineInfo()
      this.$message.success(this.$t('prompt.update_success'))
    },
    getVersion(val) {
      return this.$store.getters['app/getDictLabel']('system', val)
    },
    getQuoteType(val) {
      return val == 1 ? this.$t('companyInfo.artificial_offer') : this.$t('companyInfo.automatic_offer')
    },
    getField(val) {
      console.log(`val`, val)
      const data = this.$store.getters['app/getIndustryName'](val)
      let result = ''
      if (this.$g.isEmpty(val)) {
        result = '---'
      } else {
        result = data.length === 2 ? `${data[0]} / ${data[1]}` : `${data[1]} / ${data[2]}`
      }
      return result
    },
    getCurrency(val) {
      return this.$store.getters['app/getDictLabel']('CURRENCY', val)?.split(';')[0]
    },
    // 下拉过滤
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 获取对应的字典列表
    getDictList(type) {
      return this.$store.getters['app/getDictByType'](type)
    },
    // 供应商产品线表-交付经理列表
    async getPmList() {
      try {
        const data = await this.$http('getUserDetailList')
        this.pmList = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 分配交付经理-显示
    async showAssignPM(val) {
      this.getPmList()
      const { id, productLineConfigId, projectManager } = val
      this.assignPMFormData = {
        id: productLineConfigId,
        productLineId: id,
        projectManager: projectManager,
      }
      console.log('projectManager' + projectManager)
      this.assignPMFlag = projectManager == null ? true : false
      this.assignPMVisible = true
    },
    // 分配交付经理-取消
    async handleAssignPMCancel() {
      this.assignPMVisible = false
      this.$refs.assignPmForm.resetFields()
    },
    // 分配交付经理-确定
    async handleAssignPMOk() {
      this.$refs.assignPmForm.validate(async (valid) => {
        if (valid) {
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await this.$http('saveOrUpdateProjectManager', this.assignPMFormData)
            this.$message.success(this.$t('customerConfig.assignPMSuccess'))
            this.assignPMVisible = false
            this.$refs.assignPmForm.resetFields()
            this.getProdutionLineInfo()
          } catch (err) {
            this.$httpNotify(err)
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.customer-config {
  padding: 30px 20px 0;
  .title {
    font-weight: bold;
    margin-bottom: 18px;
  }
  .confirmTag {
    width: 82px;
    height: 24px;
    border-radius: 0;
  }
  ::v-deep {
    .priceList .ant-table-body {
      tr > th:first-child {
        padding-left: 50px !important;
      }
      tr > td:first-child {
        padding-left: 50px !important;
      }
    }
  }
  .radio {
    font-weight: bold;
    /deep/.ant-radio-button-wrapper {
      width: 110px;
      text-align: center;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 32px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .priceList .btn-container {
    display: block;
    // justify-content: space-between;
    .price-filter {
      .ant-select {
        width: 120px;
        margin-left: 10px;
      }
      .ant-btn {
        margin-left: 10px;
      }
      .bigw {
        width: 210px;
      }
    }
  }
  .btn-container {
    display: flex;
    margin: 20px 0;
  }
  .btn-update {
    width: 82px;
  }
  a {
    color: @primary-color;
  }
  // .baseInfo {
  .title-btn-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 13px;
  }
  /deep/.ant-descriptions-item-colon {
    width: 135px;
    font-weight: bold;
    color: @text_color_2;
  }
  // }
}
</style>
